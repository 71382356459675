import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Enumerable from 'linq';
import { CorteDiarioModel } from '../corte-diario.models';
import { GraphByAnalisisComponent } from '../graph-by-analisis/graph-by-analisis.component';
import { GridRutasComponent } from '../grid-rutas/grid-rutas.component';
import { ModalCambiarFronteraComponent } from '../modal-cambiar-frontera/modal-cambiar-frontera.component';
import { ModalGraphComparacionComponent } from '../modal-graph-comparacion/modal-graph-comparacion.component';
import { CallHttpService, DialogVestaService } from '@vesta/vesta';
import { CallsAnalisisService } from '../../services/calls-analisis.service';
@Component({
  selector: 'modal-comparacion',
  templateUrl: './modal-comparacion.component.html',
  styleUrls: ['./modal-comparacion.component.css']
})
export class ModalComparacionComponent implements OnInit {

  constructor(private serviceanalisis: CallsAnalisisService, private dialogService: DialogVestaService,public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: {
      Allgestiones: Array<CorteDiarioModel.GetGestionesAbiertasXRutas>,
      gestiones: Array<CorteDiarioModel.GetGestionesAbiertasXRutas>,
      plantillaSelected: CorteDiarioModel.PlantillasIndex,
      maxDias: number, mindias: number, isComparacion: boolean,
      UsuarioId: string,
      RutasXUsuario: Array<CorteDiarioModel.RutasByUsuario>
    }
  ) { }
  @ViewChild("GridRutas", { static: false }) GridRutas: GridRutasComponent;

  public aduanaIdSeleccionada: string = null;
  public maxTiempoProceso = 0;
  public minTiempoProceso = 0;
  public GridOrGraph: boolean = true;
  public isComparacion: boolean = true;
  public changefrontera: boolean = false;
  public Reload: boolean = false;

  public listGestiones: Array<CorteDiarioModel.GetGestionesAbiertasXRutas> = [];
  public listRutas: Array<CorteDiarioModel.listRutasGrid> = [];
  public listAduanas: Array<CorteDiarioModel.AduanasList> = [];
  public listGestionXRuta: Array<CorteDiarioModel.GetGestionesAbiertasXRutas>=[];
  public listrutasXUsuario: Array<CorteDiarioModel.RutasByUsuario> = [];
  public newListrutasXUsuario: Array<CorteDiarioModel.RutasByUsuario> = [];
  public listrutasXUsuarioCopy: Array<CorteDiarioModel.RutasByUsuario> = [];

  ngOnInit(): void {
    this.listGestiones = this.data.gestiones;
    this.maxTiempoProceso = this.data.maxDias;
    this.minTiempoProceso = this.data.mindias;
    this.isComparacion = this.data.isComparacion;

    console.log(this.listGestiones);
    console.log(this.data.maxDias);
    console.log(this.data.mindias);
    //setTimeout(() => {
    //this.ngData(this.data.Allgestiones)
    this.ngDataGridRutas();
    //}, 1000)
  }

  ngDataGridRutas() {
    this.data.RutasXUsuario.forEach(s => {
      s.AduanaEnSitio = Enumerable.from(s.SitiosPorRuta).where(r => r.Sitio.EsAduana == true).select(r => r.Sitio.Nombre).firstOrDefault();
      s.Selected = false;
      s.listSitios = Enumerable.from(s.SitiosPorRuta).takeExceptLast().toArray();
      //Seleccionar las rutas de la plantilla
      s.Selected = this.data.plantillaSelected.TemplateRutas.some(r => r.Ruta.Id == s.Id);
      s.SitioReferenciaId = Enumerable.from(this.data.plantillaSelected.TemplateRutas).where(s => s.Ruta.Id == s.Ruta.Id).select(s => s.SitioReferenciaId).firstOrDefault()
      //s.SitioReferenciaId= Enumerable.from(this.)
      s.NoGestiones = Enumerable.from(this.data.Allgestiones).where(r => r.Ruta.Id.toLowerCase() == s.Id.toLowerCase()).toArray().length
    });
 
    this.data.RutasXUsuario.sort((a, b) => {
      if (a.NoGestiones === 0) {
          return 1; // Coloca a después de b si a.count es 0
        } else if (b.NoGestiones === 0) {
          return -1; // Coloca a antes de b si b.count es 0
        } else {
          return 0; // Mantén el orden original si ninguno de los dos es 0
        }
      });
    
    this.listrutasXUsuario = [... this.data.RutasXUsuario];
    this.listrutasXUsuarioCopy = [... this.data.RutasXUsuario];
  }

  ngData(gestiones: Array<CorteDiarioModel.GetGestionesAbiertasXRutas>) {
    this.listRutas = [];
    this.listAduanas = [];
   // let gestionesbyruta;
    //if (this.data.plantillaSelected != null && this.data.plantillaSelected != undefined) {
      //let gestionesFiltradas = Enumerable.from(gestiones).where(s => this.data.plantillaSelected.TemplateRutas.some(r => r.Ruta.Id == s.Ruta.Id) == true).toArray();
      //let gestionesbyruta = Enumerable.from(gestionesFiltradas).groupBy(s => s.Ruta.NombreRuta).toArray();
      //console.log(gestionesbyruta)
    //} else {
    let gestionesbyruta = Enumerable.from(gestiones).groupBy(s => s.Ruta.NombreRuta).toArray();

    //}

    gestionesbyruta.forEach(gestion => {
      let rutaGestion = gestion.getSource()
      console.log(rutaGestion)
      let aduanasByrutaXGestiones = Enumerable.from(rutaGestion).selectMany(s => s.Ruta.SitiosPorRuta).where(s => s.EsAduana == true).distinct(s => s.Nombre).toArray()
      console.log("Aduanas")
      console.log(aduanasByrutaXGestiones)

      aduanasByrutaXGestiones.forEach(aduana => {
        this.listAduanas.push({ Id: aduana.Id, Nombre: aduana.Nombre })

      });
      //Seleccionar las rutas de la plantilla
      let selected = this.data.plantillaSelected.TemplateRutas.some(r => r.Ruta.Id == rutaGestion[0].Ruta.Id);
      this.listRutas.push(
        {
          RutaId: rutaGestion[0].Ruta.Id,
          Ruta: rutaGestion[0].Ruta.NombreRuta,
          Gestion: rutaGestion.length,
          SKU: Enumerable.from(rutaGestion).selectMany(s => s.CostosFacturacion).select(s => s.DetalleFactura).toArray().length,
          Aduana: Enumerable.from(aduanasByrutaXGestiones).where(s => s.EsAduana == true).select(s => s.Nombre).firstOrDefault(),
          Selected: selected,
          ListSkus:[]
          
        }
      )

    });
    
    this.listAduanas = Enumerable.from(this.listAduanas).distinct(s => s.Id).toArray();
  }

  EventModalGraficar(gestiones?: Array<CorteDiarioModel.GetGestionesAbiertasXRutas>) {
    if (this.listrutasXUsuario.length > 0 && this.listrutasXUsuario.some(s => s.Selected == true) && this.listrutasXUsuario.some(s => s.Selected == false)) {
      this.GridOrGraph = false;
      let gestionesAll;
      if (gestiones != null && gestiones != undefined) {
        gestionesAll = gestiones;

      } else {
        gestionesAll = this.data.Allgestiones
      }
      const dialogRef = this.dialog.open(ModalGraphComparacionComponent, {
        width: '100%',   // Ancho del diálogo
        height: '100%',  // Alto del diálogo
        maxWidth: '100%',
        disableClose: true,
        data: {
          gestiones: this.data.Allgestiones,
          //gestiones: this.listGestiones,
          maxDias: this.maxTiempoProceso,
          mindias: this.minTiempoProceso,
          isComparacion: this.isComparacion,
          listrutas: this.listRutas,
          listRutasXUsuario: this.listrutasXUsuario,
          usuarioId: this.data.UsuarioId
        },
        panelClass: 'dialog-comparacion2'


      });

      dialogRef.afterClosed().subscribe(result => {
        //console.log(`Dialog result: ${result}`);

      });
    } else {
      this.dialogService.NotificacionInfo('Tiene que haber al menos una ruta seleccionada y una no seleccionada.')
    }
 
  };

  EventChangeFronteraModal() {
    const dialogRef = this.dialog.open(ModalCambiarFronteraComponent, {
      width: '40%',   // Ancho del diálogo
      height: '50%',  // Alto del diálogo
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
    });
  };

  EventChangeFrontera() {
   //this.listrutasXUsuario.find(s => s.SitioReferenciaId)
    if (this.changefrontera) {
      this.changefrontera = true
    } else {
      this.changefrontera = !this.changefrontera;

    }

  }

  PostCambiarFrontera() {
    this.newListrutasXUsuario = [];

    if (this.changefrontera) {
      if (this.listrutasXUsuario.length > 0 && this.listrutasXUsuario.some(s => s.Selected == true) && this.listrutasXUsuario.some(s => s.Selected == false)) {

        //se cambio al menos una frontera
        let rutasWithSitios = [];
        let rutas = Enumerable.from(this.listrutasXUsuario).toArray()
        rutas.forEach(r => { rutasWithSitios.push({ rutaId: r.Id, SitioReferenciaId: r.SitioReferenciaId }); })

        let query = {
          "TemplateId": this.data.plantillaSelected.Id,
          "Rutas": rutasWithSitios,
          "NombrePLantilla": this.data.plantillaSelected.Nombre,
          "SitioAduanaId": this.aduanaIdSeleccionada,
          "ModifiedBy": this.data.UsuarioId,
          "esPublica": this.data.plantillaSelected.EsPublica,
          "favorita": this.data.plantillaSelected.Favorita,
        }

        this.GetGestionesAbiertasXRutas(rutasWithSitios);
        this.serviceanalisis.PostPlantillasUpdate(query)
          .then(result => {
            if (result) {

              //array con las rutas que se le asignaron un nuevo sitio referencia
              //se envia al componente de graph por si se vuelve abrir este modal
              this.newListrutasXUsuario = [...this.listrutasXUsuario];
              //this.GetGestionesAbiertasXRutas(result.Message.SitioAduanaId);
              this.dialogService.NotificacionSuccess('Frontera Actualizada correctamente');

            }
          })
          .catch(e => {
            this.dialogService.NotificacionAlert(e);

          })

      } else {
        this.dialogService.NotificacionInfo('Tiene que haber al menos una ruta seleccionada y una no seleccionada.')
      }
  
    } else {
      //no se cambio ninguna frontera, graficar normalmente
      this.EventModalGraficar();
    }

  };

  GetGestionesAbiertasXRutas(rutas) {

    //let rutasWithSitios = [];
    //let rutas = Enumerable.from(this.data.plantillaSelected.TemplateRutas).toArray()
    //rutas.forEach(r => { rutasWithSitios.push({ rutaId: r.Ruta.Id, SitioReferenciaId: r.SitioReferenciaId }); })

    let json = {
      "rutas": rutas,
     // "sitioAduanaId": sitioAduanaId,
      "diasAntesxFechaUltimoSello": null,
      "fechaInicio": null,
      "fechaFin": null,
    }

    this.serviceanalisis.GetGestionesAbiertasXRutas(json)
      .then(result => {
        result.forEach(gestion => {
          gestion.TiempoProceso = parseFloat(gestion.TiempoProceso.toFixed(2))
        })
        //Se actualizo la frontera
        //Traer las nuevas gestiones son las que seran comparadas
        this.data.Allgestiones = result;
        this.EventModalGraficar()
        //para recargar el grid de plantillas en el main
        this.Reload = true;
      })
      .catch(e => {
        this.dialogService.NotificacionAlert(e);

      });
  }


}
