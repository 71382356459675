import { AfterContentInit, Inject } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Enumerable from 'linq';
import { CallHttpService, DialogVestaService } from '@vesta/vesta';
import { CallsAnalisisService } from '../../services/calls-analisis.service';
import { CorteDiarioModel } from '../corte-diario.models';
import { GridRutasComponent } from '../grid-rutas/grid-rutas.component';
import { ModalCambiarFronteraComponent } from '../modal-cambiar-frontera/modal-cambiar-frontera.component';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'modal-crear-plantilla',
  templateUrl: './modal-crear-plantilla.component.html',
  styleUrls: ['./modal-crear-plantilla.component.css'],
})
export class ModalCrearPlantillaComponent implements OnInit, AfterContentInit {
  //este componente es reciclado para crear y actualiazr la plantilla
  constructor(
    private serviceanalisis: CallsAnalisisService,
    private dialogService: DialogVestaService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      //GestionesXRuta: Array<CorteDiarioModel.GetGestionesAbiertasXRutas>,
      plantillaSelected: CorteDiarioModel.PlantillasIndex;
      UsuarioId: string;
      isEdit: boolean;
      RutasXUsuario: Array<CorteDiarioModel.RutasByUsuario>;
    }
  ) {}

  //@ViewChild("GridRutas", { static: false }) GridRutas: GridRutasComponent = new GridRutasComponent;
  @ViewChild(GridRutasComponent, { static: false })
  GridRutas: GridRutasComponent;

  public UsuarioId: string = '';
  public titleModal: string = '';
  public searchTermRuta: string = null;
  public searchTermSku: string = null;

  public Reload: boolean = false;
  public isCheckedAll: boolean = false;

  public createPlantilla: CorteDiarioModel.PlantillaCoU;
  public listRutas: Array<CorteDiarioModel.listRutasGrid> = [];
  public listRutasCopy: Array<CorteDiarioModel.listRutasGrid> = [];
  public listSkusFilter: Array<CorteDiarioModel.DetalleFactura> = [];
  public listrutasXUsuario: Array<CorteDiarioModel.RutasByUsuario> = [];
  public listrutasXUsuarioCopy: Array<CorteDiarioModel.RutasByUsuario> = [];
  public listGestionXRuta: Array<CorteDiarioModel.GetGestionesAbiertasXRutas> =
    [];

  ngOnInit(): void {
    this.UsuarioId = this.data.UsuarioId;
    //this.listGestionXRuta = this.data.GestionesXRuta;
    this.createPlantilla = {
      NombrePLantilla: '',
      CreatedById: '',
      DiasAntesxFechaUltimoSello: 0,
      EsPublica: true,
      Rutas: [],
      UsuarioId: '',
      DiaMax: 0,
      DiaMin: 0,
      Favorita: false,
    };
  }

  ngAfterContentInit(): void {
    //cada vez que entre a este componente verificara si existe esta data
    //para no recargar el metodo

    //this.listGestionXRuta = JSON.parse(localStorage.getItem('gesionByRutasAll'))
    //if (this.listGestionXRuta != null && this.listGestionXRuta != undefined) {
    //  if (this.listGestionXRuta.length > 0) {
    //    this.ngData(this.listGestionXRuta)

    //  }
    //} else {
    //  let rutas = Enumerable.from(this.data.RutasXUsuario).select(s => s.Id).toArray();
    //  this.GetGestionesAbiertasXRutas(rutas);

    //}

    //this.ngData(this.listGestionXRuta)
    this.ngDataGridRutas();
    this.titleModal = this.data.isEdit ? 'EDITAR PLANTILLA' : 'CREAR PLANTILLA';
  }

  ngDataGridRutas() {
    this.data.RutasXUsuario.forEach((RutaU) => {
      RutaU.AduanaEnSitio = Enumerable.from(RutaU.SitiosPorRuta)
        .where((r) => r.Sitio.EsAduana == true)
        .select((r) => r.Sitio.Nombre)
        .firstOrDefault();
      RutaU.Selected = false;
      RutaU.listSitios = Enumerable.from(RutaU.SitiosPorRuta)
        .takeExceptLast()
        .toArray();
      if (RutaU.RutaCompuesta.length > 0) {
        let sitiosRC = Enumerable.from(RutaU.RutaCompuesta)
          .selectMany((s) => s.SitiosPorRuta)
          .toArray();
        sitiosRC.forEach((sitiorc) => RutaU.listSitios.push(sitiorc));
      }
      RutaU.SitioReferenciaId = null;
      if (
        this.data.isEdit &&
        this.data.plantillaSelected != undefined &&
        this.data.plantillaSelected != null
      ) {
        //Fue seleccionada una plantilla para editarse
        RutaU.Selected = this.data.plantillaSelected.TemplateRutas.some(
          (ruta) => ruta.Ruta.Id == RutaU.Id
        );
        RutaU.SitioReferenciaId = Enumerable.from(
          this.data.plantillaSelected.TemplateRutas
        )
          .where((RutaZ) => RutaZ.Ruta.Id == RutaU.Id)
          .select((s) => s.SitioReferenciaId)
          .firstOrDefault();
      }
    });
    this.listrutasXUsuario = [...this.data.RutasXUsuario];
    this.listrutasXUsuarioCopy = [...this.data.RutasXUsuario];
    console.log(this.listrutasXUsuario);
    if (
      this.data.isEdit &&
      this.data.plantillaSelected != undefined &&
      this.data.plantillaSelected != null
    ) {
      //Fue seleccionada una plantilla para editarse
      this.createPlantilla = {
        TemplateId: this.data.plantillaSelected.Id,
        NombrePLantilla: this.data.plantillaSelected.Nombre,
        ModifiedBy: this.UsuarioId,
        DiasAntesxFechaUltimoSello:
          this.data.plantillaSelected.DiasAntesxFechaUltimoSello,
        EsPublica: this.data.plantillaSelected.EsPublica,
        //SitioAduanaId: this.data.plantillaSelected.SitioAduanaId,
        Rutas: [],
        UsuarioId: this.UsuarioId,
        DiaMax: this.data.plantillaSelected.DiaMax,
        DiaMin: this.data.plantillaSelected.DiaMin,
        Favorita: this.data.plantillaSelected.Favorita,
      };
    }
  }

  ngData(gestiones: Array<CorteDiarioModel.GetGestionesAbiertasXRutas>) {
    this.listRutas = [];
    let listskus: CorteDiarioModel.DetalleFactura[] = [];
    let gestionesbyruta = Enumerable.from(gestiones)
      .groupBy((s) => s.Ruta.NombreRuta)
      .toArray();
    gestionesbyruta.forEach((gestion) => {
      let rutaGestion = gestion.getSource();
      console.log(rutaGestion);

      let isSelected = false;
      if (
        this.data.isEdit &&
        this.data.plantillaSelected != undefined &&
        this.data.plantillaSelected != null
      ) {
        //Fue seleccionada una plantilla para editarse
        isSelected = this.data.plantillaSelected.TemplateRutas.some(
          (ruta) => ruta.Ruta.Id == rutaGestion[0].Ruta.Id
        );
      }

      listskus = Enumerable.from(rutaGestion)
        .selectMany((s) => s.CostosFacturacion)
        .selectMany((s) => s.DetalleFactura)
        .toArray();

      listskus.forEach((sku) => {
        sku.ConcatSkuDescr = sku.CodigoSku + ' ' + sku.ProductoDescripcion;
        this.listSkusFilter.push(sku);
      });
      this.listRutas.push({
        RutaId: rutaGestion[0].Ruta.Id,
        Ruta: rutaGestion[0].Ruta.NombreRuta,
        Gestion: rutaGestion.length,
        SKU: Enumerable.from(rutaGestion)
          .selectMany((s) => s.CostosFacturacion)
          .select((s) => s.DetalleFactura)
          .toArray().length,
        Aduana: Enumerable.from(rutaGestion[0].Ruta.SitiosPorRuta)
          .where((s) => s.EsAduana == true)
          .select((s) => s.Nombre)
          .firstOrDefault(),
        Selected: isSelected,
        ListSkus: listskus,
      });
    });
    //si no existe al menos una gestion con alguna de las rutas del usuario, agregarla y ponerla en cero los datos
    this.data.RutasXUsuario.forEach((ruta) => {
      if (
        !this.listRutas.some(
          (s) => s.RutaId.toLowerCase() == ruta.Id.toLowerCase()
        )
      ) {
        let isSelected = false;
        if (
          this.data.isEdit &&
          this.data.plantillaSelected != undefined &&
          this.data.plantillaSelected != null
        ) {
          //Fue seleccionada una plantilla para editarse
          isSelected = this.data.plantillaSelected.TemplateRutas.some(
            (rutaS) => rutaS.Ruta.Id == ruta.Id
          );
        }

        this.listRutas.push({
          RutaId: ruta.Id,
          Ruta: ruta.NombreRuta,
          Gestion: 0,
          SKU: 0,
          Aduana: Enumerable.from(ruta.SitiosPorRuta)
            .where((s) => s.Sitio.EsAduana == true)
            .select((s) => s.Sitio.Nombre)
            .firstOrDefault(),
          Selected: isSelected,
          ListSkus: [],
        });
      }
    });

    this.listSkusFilter = Enumerable.from(this.listSkusFilter)
      .distinct((s) => s.CodigoSku)
      .toArray();
    this.listRutasCopy = [...this.listRutas];
    if (
      this.data.isEdit &&
      this.data.plantillaSelected != undefined &&
      this.data.plantillaSelected != null
    ) {
      //Fue seleccionada una plantilla para editarse
      this.createPlantilla = {
        TemplateId: this.data.plantillaSelected.Id,
        NombrePLantilla: this.data.plantillaSelected.Nombre,
        ModifiedBy: this.UsuarioId,
        DiasAntesxFechaUltimoSello:
          this.data.plantillaSelected.DiasAntesxFechaUltimoSello,
        EsPublica: this.data.plantillaSelected.EsPublica,
        //SitioAduanaId: this.data.plantillaSelected.SitioAduanaId,
        Rutas: [],
        UsuarioId: this.UsuarioId,
        DiaMax: this.data.plantillaSelected.DiaMax,
        DiaMin: this.data.plantillaSelected.DiaMin,
        Favorita: this.data.plantillaSelected.Favorita,
      };
    }
  }

  FilterRutas() {
    console.log(this.searchTermRuta);
    console.log(this.searchTermSku);
    if (this.searchTermRuta === null && this.searchTermSku === null) {
      this.listRutas = [...this.listRutasCopy];
    } else {
      this.listRutas = this.listRutasCopy.filter((ruta) => {
        // Filtrar usuarios que coincidan con el término de búsqueda
        return (
          ruta.Ruta.toLowerCase().startsWith(
            this.searchTermRuta?.toLowerCase()
          ) || ruta.ListSkus.some((sku) => sku.CodigoSku == this.searchTermSku)
        );
      });
    }
  }

  EventSelectAll() {
    if (this.isCheckedAll) {
      this.listrutasXUsuario.forEach((s) => (s.Selected = true));
    } else {
      this.listrutasXUsuario.forEach((s) => (s.Selected = false));
    }
    this.listrutasXUsuario = [...this.listrutasXUsuario];
  }

  EventChangeFronteraModal() {
    const dialogRef = this.dialog.open(ModalCambiarFronteraComponent, {
      width: '40%', // Ancho del diálogo
      height: '50%', // Alto del diálogo
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log(`Dialog result: ${result}`);
    });
  }

  EventGuardar() {
    if (!this.data.isEdit) {
      //crear
      this.PostPlantillaCreate();
    } else {
      //actualizar
      this.PostPlantillaUpdate();
    }
  }

  GetGestionesAbiertasXRutas(rutas: string[]) {
    let query = {
      rutaId: rutas,
      sitioAduanaId: null,
      diasAntesxFechaUltimoSello: null,
    };
    this.serviceanalisis
      .GetGestionesAbiertasXRutas(query)
      .then((result) => {
        result.forEach((gestion) => {
          gestion.TiempoProceso = parseFloat(gestion.TiempoProceso.toFixed(2));
        });
        this.listGestionXRuta = result;

        this.ngData(this.listGestionXRuta);
        // this.openDialog(plantilla);

        //Guardamos las rutas ya estructuradas del grid
        //cada vez que entre a este componente verificara si existe esta data
        //para no recargar el metodo
        localStorage.setItem(
          'gesionByRutasAll',
          JSON.stringify(this.listGestionXRuta)
        );
      })
      .catch((e) => {
        this.dialogService.NotificacionAlert(e);
      });
  }

  PostPlantillaCreate() {
    let rutas = Enumerable.from(this.listrutasXUsuario)
      .where((s) => s.Selected == true)
      .toArray();
    rutas.forEach((r) => {
      this.createPlantilla.Rutas.push({
        rutaId: r.Id,
        SitioReferenciaId: r.SitioReferenciaId,
      });
    });
    console.log(this.listrutasXUsuario);
    console.log(this.createPlantilla);
    this.createPlantilla.CreatedById = this.UsuarioId;
    //this.createPlantilla.SitioAduanaId = null;
    this.createPlantilla.DiasAntesxFechaUltimoSello = 1;
    this.createPlantilla.UsuarioId = this.UsuarioId;

    this.serviceanalisis
      .PostPlantillasCreate(this.createPlantilla)
      .then((result) => {
        if (result) {
          if (this.createPlantilla.Favorita) {
            this.PostPlantillaCreateFAv(result.Message.Id);
          } else {
            this.Reload = true;
            this.CerrarModal();
          }

          this.dialogService.NotificacionSuccess(
            'Plantilla creada correctamente'
          );
        }
      })
      .catch((e) => {
        this.dialogService.NotificacionAlert(e);
      });
  }

  PostPlantillaUpdate() {
    let rutas = Enumerable.from(this.listrutasXUsuario)
      .where((s) => s.Selected == true)
      .toArray();
    rutas.forEach((r) => {
      this.createPlantilla.Rutas.push({
        rutaId: r.Id,
        SitioReferenciaId: r.SitioReferenciaId,
      });
    });
    this.createPlantilla.DiaMax =
      this.createPlantilla.DiaMax == 0 ? null : this.createPlantilla.DiaMax;
    this.createPlantilla.DiaMin =
      this.createPlantilla.DiaMin == 0 ? null : this.createPlantilla.DiaMin;

    this.serviceanalisis
      .PostPlantillasUpdate(this.createPlantilla)
      .then((result) => {
        if (result) {
          //if (this.createPlantilla.Favorita) {
          this.PostPlantillaCreateFAv(result.Message.Id);

          //} else {
          //  this.Reload = true;
          //  this.CerrarModal();

          //};

          this.dialogService.NotificacionSuccess(
            'Plantilla Actualizada correctamente'
          );
        }
      })
      .catch((e) => {
        this.dialogService.NotificacionAlert(e);
      });
  }

  PostPlantillaCreateFAv(idPlantilla: string) {
    let query = {
      templateId: idPlantilla,
      favorita: this.createPlantilla.Favorita,
      modifiedBy: this.UsuarioId,
    };

    this.serviceanalisis
      .PostPlantillasFav(query)
      .then((result) => {
        if (result) {
          this.Reload = true;
          this.CerrarModal();
        }
      })
      .catch((e) => {
        this.dialogService.NotificacionAlert('Plantilla Favorita Error=  ' + e);
      });
  }

  CerrarModal() {
    this.dialog.closeAll();
  }
}
