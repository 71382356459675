import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Enumerable from 'linq';
import { CorteDiarioModel } from '../corte-diario.models';
import * as moment from 'moment';
import { ModalBitacoraComponent } from '../modal-bitacora/modal-bitacora.component';

@Component({
  selector: 'modal-gestiones',
  templateUrl: './modal-gestiones.component.html',
  styleUrls: ['./modal-gestiones.component.css'],
})
export class ModalGestionesComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      gestiones: Array<CorteDiarioModel.GetGestionesAbiertasXRutas>;
      tipo: string;
      usuarioId: string;
    }
  ) {}
  public showTooltip: boolean = false;
  public title = '';
  public listGestiones: Array<CorteDiarioModel.GetGestionesAbiertasXRutas> = [];
  public listGestionesCopy: Array<CorteDiarioModel.GetGestionesAbiertasXRutas> =
    [];
  public newArrayGestion: Array<CorteDiarioModel.GetGestionesAbiertasXRutas> =
    [];
  public newArrayGestionCopy: Array<CorteDiarioModel.GetGestionesAbiertasXRutas> =
    [];
  public arrayListItem: Array<{ Descripcion: string }> = [];
  positionsArrays = { top: '0px', left: '0px' };

  ngOnInit(): void {
    this.listGestiones = this.data.gestiones;
    this.listGestionesCopy = [...this.data.gestiones];
    this.ngGenerateGrid();
  }

  ngGenerateGrid() {
    console.log(this.listGestiones);

    this.newArrayGestion =
      this.data.tipo == 'Origen'
        ? Enumerable.from(this.listGestiones)
            .where((s) => s.Segmentacion.Origen?.SitioActual == true)
            .toArray()
        : this.data.tipo == 'TransitoUno'
        ? Enumerable.from(this.listGestiones)
            .where((s) =>
              s.Segmentacion.TransitoUno.some(
                (st1) => st1?.SitioActual == true || st1?.SegmentoActual == true
              )
            )
            .toArray()
        : this.data.tipo == 'Frontera'
        ? Enumerable.from(this.listGestiones)
            .where((s) =>
              s.Segmentacion.Frontera?.some((st1) => st1.SitioActual == true)
            )
            .toArray()
        : this.data.tipo == 'TransitoDos'
        ? Enumerable.from(this.listGestiones)
            .where((s) =>
              s.Segmentacion.TransitoDos.some(
                (st1) => st1?.SitioActual == true || st1?.SegmentoActual == true
              )
            )
            .toArray()
        : this.data.tipo == 'Destino'
        ? Enumerable.from(this.listGestiones)
            .where((s) =>
              s.Segmentacion.Destino?.some((st1) => st1.SitioActual == true)
            )
            .toArray()
        : this.listGestiones;
    console.log(this.newArrayGestion);
    if (this.newArrayGestion.length > 0) {
      this.newArrayGestion.forEach((gestion) => {
        gestion.CostoTotal = parseFloat(gestion.CostoTotal.toFixed(2));
        gestion.Incidencias = [];
        gestion.Casos = [];
        gestion.Alertas = [];
        gestion.Eventos = [];
        if (gestion.Segmentacion.Origen.SitioActual == true) {
          gestion.SitioActual = gestion.Segmentacion.Origen.Nombre;
          let eta = Enumerable.from(gestion.Alertas)
            .where((s) => s.SitioId == gestion.Segmentacion.Origen.SitioId)
            .select((s) => s.Eta)
            .firstOrDefault();
          gestion.ETA = moment(eta).format('DD-MM-YYYY');
        } else if (
          gestion.Segmentacion.TransitoUno.some(
            (st1) => st1.SegmentoActual == true || st1.SitioActual == true
          )
        ) {
          let nombre = gestion.Segmentacion.TransitoUno.find(
            (st1) => st1.SegmentoActual == true || st1.SitioActual == true
          ).Nombre;
          gestion.SitioActual =
            nombre != undefined && nombre != null ? nombre : 'Transito 1';

          let id = gestion.Segmentacion.TransitoUno.find(
            (st1) => st1.SegmentoActual == true || st1.SitioActual == true
          ).SitioId;
          let eta = gestion.Segmentacion.TransitoUno.find(
            (st1) => st1.SegmentoActual == true || st1.SitioActual == true
          ).Eta;
          //let eta = Enumerable.from(gestion.Alertas).where(s => s.SitioId == id).select(s => s.Eta).firstOrDefault();

          gestion.ETA = moment(eta).format('DD-MM-YYYY');
        } else if (
          gestion.Segmentacion.Frontera.some((st1) => st1.SitioActual == true)
        ) {
          gestion.SitioActual = gestion.Segmentacion.Frontera.find(
            (st1) => st1.SitioActual == true
          ).Nombre;
          let eta = Enumerable.from(gestion.Alertas)
            .where((s) =>
              gestion.Segmentacion.Frontera.some(
                (sf) => sf.SitioId == s.SitioId
              )
            )
            .select((s) => s.Eta)
            .firstOrDefault();
          gestion.ETA = moment(eta).format('DD-MM-YYYY');
        } else if (
          gestion.Segmentacion.TransitoDos.some(
            (st1) => st1.SegmentoActual == true || st1.SitioActual == true
          )
        ) {
          let nombre = gestion.Segmentacion.TransitoDos.find(
            (st1) => st1.SegmentoActual == true || st1.SitioActual == true
          ).Nombre;
          gestion.SitioActual =
            nombre != undefined && nombre != null ? nombre : 'Transito 2';
          let id = gestion.Segmentacion.TransitoDos.find(
            (st1) => st1.SegmentoActual == true || st1.SitioActual == true
          ).SitioId;
          let eta = gestion.Segmentacion.TransitoDos.find(
            (st1) => st1.SegmentoActual == true || st1.SitioActual == true
          ).Eta;
          //let eta = Enumerable.from(gestion.Alertas).where(s => s.SitioId == id).select(s => s.Eta).firstOrDefault();
          gestion.ETA = moment(eta).format('DD-MM-YYYY');
        } else if (
          gestion.Segmentacion.Destino.some((st1) => st1.SitioActual == true)
        ) {
          gestion.SitioActual = gestion.Segmentacion.Destino.find(
            (st1) => st1.SitioActual == true
          ).Nombre;
          let eta = Enumerable.from(gestion.Alertas)
            .where((s) =>
              gestion.Segmentacion.Destino.some((sf) => sf.SitioId == s.SitioId)
            )
            .select((s) => s.Eta)
            .firstOrDefault();
          gestion.ETA = moment(eta).format('DD-MM-YYYY');
        }

        //const array = Object.entries(gestion.Segmentacion).map(([clave, valor]) => ({
        //  clave,
        //  valor: Array.isArray(valor) ? valor : Object.entries(valor)
        //}));
        //console.log(array);

        // gestion.UltimoSello = gestion.UltimoSello.toString() != '0001-01-01T00:00:00' ? moment(gestion.UltimoSello, moment.ISO_8601).format('DD-MM-YYYY') : 'Sin Fecha';

        const fechaMoment = moment(gestion.UltimoSello, 'YYYY-MM-DDTHH:mm:ss');
        if (fechaMoment.isValid()) {
          gestion.UltimoSello = fechaMoment.format('DD-MM-YYYY');
          gestion.UltimoSello =
            gestion.UltimoSello.toString() === '01-01-0001'
              ? 'Sin Fecha'
              : gestion.UltimoSello;
        } else {
          gestion.UltimoSello = 'Sin Fecha';
        }
      });
    }
    this.newArrayGestion.forEach((gestion) => {
      gestion.Expandible = false;
      gestion.GestionesHijas.forEach((gestionH) => {
        gestionH.SitioActual = gestionH.Segmentacion?.Origen.Nombre;
        let eta = Enumerable.from(gestionH.Alertas)
          .where((s) => s.SitioId == gestionH.Segmentacion?.Origen.SitioId)
          .select((s) => s.Eta)
          .firstOrDefault();
        gestionH.ETA = moment(eta).format('DD-MM-YYYY');
      });
      //gestion.UltimoSello = gestion.UltimoSello.toString() != '0001-01-01T00:00:00' && gestion.UltimoSello.toString() != "Invalid date" && gestion.UltimoSello.toString() != 'Sin Fecha' ? moment(new Date(gestion.UltimoSello)).format('DD-MM-YYYY') : 'Sin Fecha';
    });
    this.newArrayGestionCopy = [...this.newArrayGestion];
  }

  EventModalBitacora(gestion: CorteDiarioModel.GetGestionesAbiertasXRutas) {
    const dialogRef = this.dialog.open(ModalBitacoraComponent, {
      width: '90%', // Ancho del diálogo
      height: '90%', // Alto del diálogo
      disableClose: true,
      data: {
        gestionSelected: gestion,
        usuarioid: this.data.usuarioId,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log(`Dialog result: ${result}`);
    });
  }

  Eventtooltip(
    gestion: CorteDiarioModel.GetGestionesAbiertasXRutas,
    type: string
  ) {
    console.log('tooltip');
    console.log(gestion);
    //this.arrayFamiliasByPLantilla = arrayFamilias;
    this.arrayListItem = [];
    if (gestion != null) {
      if (type == 'Facturas') {
        gestion.CostosFacturacion.forEach((s) => {
          this.arrayListItem.push({ Descripcion: s.NumeroFactura });
        });
        this.title = 'Factura Comercial';
      } else if (type == 'DocumentoT') {
        console.log(gestion.DocumentosTransporte);
        gestion.DocumentosTransporte.forEach((s) => {
          this.arrayListItem.push({ Descripcion: s.CorrelativoDocumento });
        });
        this.title = 'Documento Transporte';
      }
    }
    this.arrayListItem = [...this.arrayListItem];
    console.log(this.arrayListItem);
    let id = type;
    let elemento = document.getElementById(id);
    console.log(elemento);
    if (elemento != null) {
      let posicion = elemento.getBoundingClientRect();
      console.log(posicion);

      this.positionsArrays = {
        top: Math.abs(posicion.top) + 80 + 'px',
        left: Math.abs(posicion.left) + 100 + 'px',
      };
    }

    this.showTooltip = !this.showTooltip;
  }

  public filtersValues: Array<CorteDiarioModel.FiltrosGrid> = [];

  EventFiltros(event, descripcion) {
    console.log(event);
    console.log(event.target.name);
    console.log(event.target.value);
    console.log(descripcion);
    if (event.target.value == '' || event.target.value == null) {
      if (this.filtersValues.some((s) => s.key == event.target.name)) {
        //elimina el valor si este es vacio
        let idndex = this.filtersValues.findIndex(
          (s) => s.key == event.target.name
        );
        if ((idndex = -1)) {
          this.filtersValues.splice(idndex, 1);
        }
      }
    } else {
      if (this.filtersValues.some((s) => s.key == event.target.name)) {
        //si ya existe un filtro reemplaza el valor
        let idndex = this.filtersValues.findIndex(
          (s) => s.key == event.target.name
        );
        if (idndex != -1) {
          this.filtersValues[idndex].value = event.target.value;
        }
      } else {
        //Sino lo agrega como uno nuevo
        this.filtersValues.push({
          key: event.target.name,
          value: event.target.value,
          descripcion: descripcion,
        });
      }
    }
    console.log(this.filtersValues);
    this.filterData(this.filtersValues);
  }

  filterData(filters: CorteDiarioModel.FiltrosGrid[]) {
    let context = this;
    // Supongamos que 'data' es la variable que contiene tu array de objetos
    let Data = this.newArrayGestion;

    this.newArrayGestion = [...this.newArrayGestionCopy];

    //reiniciar el array cada vez que se agrega un filtro
    //context.listGestiones = JSON.parse(JSON.stringify(context.listGestionesCopy));
    //filters.forEach(filtro => {

    //  filtro.descripcion

    //  this.newArrayGestion = Enumerable.from(this.newArrayGestionCopy).where(s=>s.)
    //});

    this.newArrayGestion = this.newArrayGestionCopy.filter((item) => {
      return filters.every((filter) => {
        let value =
          item[filter.key as keyof CorteDiarioModel.GetGestionesAbiertasXRutas];

        if (filter.key == 'CostosFacturacion') {
          return (
            value != null &&
            Array.isArray(value) &&
            value.some((s) => s.NumeroFactura.includes(filter.value))
          );
        } else if (filter.key == 'DocumentosTransporte') {
          return (
            value != null &&
            Array.isArray(value) &&
            value.some((s) => s.CorrelativoDocumento.includes(filter.value))
          );
        } else if (
          ['Notas', 'Eventos', 'Alertas', 'Incidencias', 'Casos'].includes(
            filter.key
          )
        ) {
          return (
            value != null &&
            Array.isArray(value) &&
            value.length === parseInt(filter.value, 10)
          );
        } else {
          return (
            value != null &&
            value.toString().toLowerCase().includes(filter.value.toLowerCase())
          );
        }
      });
    });
    console.log(this.newArrayGestion);
  }
}
