import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { VestaModule } from '@vesta/vesta';
import { NgMetro4Module } from 'ng-metro4';
import { ModuloCoreModule } from '../_core/modulo-core/modulo-core.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CorteDiarioComponent } from './corte-diario/corte-diario.component';
import { CorteDiarioModule } from './corte-diario/corte-diario.module';

//import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
    CorteDiarioComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    //BrowserAnimationsModule,
    AppRoutingModule,
    NgMetro4Module,
    VestaModule.forRoot({ isAuthentication: false }),
    ModuloCoreModule,
    CorteDiarioModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
