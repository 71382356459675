import { AfterContentInit, AfterViewInit } from '@angular/core';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import Enumerable from 'linq';
import { CallsAnalisisService } from '../../services/calls-analisis.service';
import { CorteDiarioModel } from '../corte-diario.models';
import { GraphByAnalisisComponent } from '../graph-by-analisis/graph-by-analisis.component';
import { CallHttpService, DialogVestaService } from '@vesta/vesta';

@Component({
  selector: 'modal-graph-by-analisis',
  templateUrl: './modal-graph-by-analisis.component.html',
  styleUrls: ['./modal-graph-by-analisis.component.css'],
})
export class ModalGraphByAnalisisComponent
  implements OnInit, AfterContentInit, AfterViewInit
{
  constructor(
    private serviceanalisis: CallsAnalisisService,
    private dialogService: DialogVestaService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      //GestionesXRuta: Array<CorteDiarioModel.GetGestionesAbiertasXRutas>,
      plantillaSelected: CorteDiarioModel.PlantillasIndex;
      UsuarioId: string;
      rutasXUsuario: Array<CorteDiarioModel.RutasByUsuario>;
    }
  ) {}

  @ViewChild('graphanalisis', { static: false })
  graphanalisis: GraphByAnalisisComponent;

  public aduana: string = null;
  public Reload: boolean = false;

  public listGestionXRuta: Array<CorteDiarioModel.GetGestionesAbiertasXRutas>;

  ngOnInit(): void {
    let rutasWithSitios = [];
    let rutas = Enumerable.from(
      this.data.plantillaSelected.TemplateRutas
    ).toArray();
    rutas.forEach((r) => {
      rutasWithSitios.push({
        rutaId: r.Ruta.Id,
        SitioReferenciaId: r.SitioReferenciaId,
      });
    });

    this.GetGestionesAbiertasXRutas(rutasWithSitios);
  }

  ngAfterViewInit(): void {}

  ngAfterContentInit(): void {}

  GetGestionesAbiertasXRutas(rutas: any[]) {
    let query = {
      fechaInicio: null,
      fechaFin: null,
      rutas: rutas,
      // "sitioAduanaId": this.aduana,
      diasAntesxFechaUltimoSello: null,
    };
    this.serviceanalisis
      .GetGestionesAbiertasXRutas(query)
      .then((result) => {
        result.forEach((gestion) => {
          gestion.TiempoProceso = parseFloat(gestion.TiempoProceso.toFixed(2));
          gestion.CostoTotal = parseFloat(gestion.CostoTotal.toFixed(2));
          //gestion.Segmentacion.TransitoUno.forEach(tuno => {
          //  let primersitio = Enumerable.from(gestion.Ruta.SitiosPorRuta).where(s => s.Id == tuno.SitioOrigen).select(s => s.Nombre).firstOrDefault();
          //  let segsitio = Enumerable.from(gestion.Ruta.SitiosPorRuta).where(s => s.Id == tuno.SitioDestino).select(s => s.Nombre).firstOrDefault();
          //  tuno.Nombre = primersitio + '/' + segsitio;
          //})
          //gestion.Segmentacion.TransitoDos.forEach(tuno => {
          //  let primersitio = Enumerable.from(gestion.Ruta.SitiosPorRuta).where(s => s.Id == tuno.SitioOrigen).select(s => s.Nombre).firstOrDefault();
          //  let segsitio = Enumerable.from(gestion.Ruta.SitiosPorRuta).where(s => s.Id == tuno.SitioDestino).select(s => s.Nombre).firstOrDefault();
          //  tuno.Nombre = primersitio + '/' + segsitio;
          //})
        });
        this.listGestionXRuta = result;
        this.GetData();
      })
      .catch((e) => {
        this.dialogService.NotificacionAlert(e);
      });
  }

  GetData() {
    //this.listGestionXRuta = this.data.GestionesXRuta;
    this.graphanalisis.UsuarioId = this.data.UsuarioId;
    this.graphanalisis.PlantillaSelected = this.data.plantillaSelected;
    this.graphanalisis.ngData();

    console.log('plantila selecionadaaaaa');
    console.log(this.data.plantillaSelected);

    //Todas las gestiones que retorna el metodo, sirven para excluidos
    this.graphanalisis.AlllistGestiones = this.listGestionXRuta;
    this.graphanalisis.listGestiones = this.listGestionXRuta;
    this.graphanalisis.listrutasXUsuario = this.data.rutasXUsuario;

    //gestiones que se trabajaran exactamente por la ruta de la plantilla y si hay tiempo max y min filtrarlas, porque la plantilla solo trabajara con esas
    //igual que el comparar

    if (
      this.data.plantillaSelected?.DiaMin >= 0 &&
      this.data.plantillaSelected?.DiaMax > 0
    ) {
      this.graphanalisis.listGestiones = Enumerable.from(this.listGestionXRuta)
        .where(
          (s) =>
            s.TiempoProceso >= this.data.plantillaSelected?.DiaMin &&
            s.TiempoProceso <= this.data.plantillaSelected?.DiaMax
        )
        .toArray();
      console.log(this.graphanalisis.listGestiones);
    } else {
      this.graphanalisis.listGestiones = this.listGestionXRuta;
      console.log(this.graphanalisis.listGestiones);
    }

    this.graphanalisis.EventObtenerGestiones(this.listGestionXRuta, true);
    console.log(this.listGestionXRuta);
  }

  dataReload(datos: boolean) {
    this.Reload = datos;
  }
  gb;
}
