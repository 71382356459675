import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { GraphByAnalisisComponent } from './graph-by-analisis/graph-by-analisis.component';
import { ModalCrearPlantillaComponent } from './modal-crear-plantilla/modal-crear-plantilla.component';
import { ModalCambiarFronteraComponent } from './modal-cambiar-frontera/modal-cambiar-frontera.component';
import { ModalGraphByAnalisisComponent } from './modal-graph-by-analisis/modal-graph-by-analisis.component';
import { CallsAnalisisService } from '../services/calls-analisis.service';
import { CallHttpService, DialogVestaService } from '@vesta/vesta';
import { CorteDiarioModel } from './corte-diario.models';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import Enumerable from 'linq';
import { ModalDeleteTemplateComponent } from './modal-delete-template/modal-delete-template.component';

@Component({
  selector: 'corte-diario',
  templateUrl: './corte-diario.component.html',
  styleUrls: ['./corte-diario.component.css']
})
export class CorteDiarioComponent implements OnInit {

  constructor(public dialog: MatDialog, private serviceanalisis: CallsAnalisisService,
    private dialogService: DialogVestaService, private route: ActivatedRoute) { }

  public UsuarioId: string = '';
  public ordenAscendente: boolean = false;

  public listPlantillas: Array<CorteDiarioModel.PlantillasIndex> = [];
  public listPlantillasFavs: Array<CorteDiarioModel.PlantillasIndex> = [];
  public listGrid: Array<{ Nombre: string, CreatedBy: string, CreatedDate: string, ModifyDate: string }> = [];
  //public listGestionXRuta: Array<CorteDiarioModel.GetGestionesAbiertasXRutas>;
  public listRutasByUsuario: Array<CorteDiarioModel.RutasByUsuario>=[];
  showSort: { [key: string]: boolean } = {};

  ngOnInit(): void {
    this.UsuarioId = this.route.snapshot.params['UsuarioId'];

    this.listGrid = [...this.listGrid];
    console.log(this.listGrid)

   // this.GetGestionesAbiertasXRutas();
    this.GetPlantillas();

    this.GetRutasXUsuario()

    this.showSort = { Nombre: false };

  }

  showSortButton(column: string) {
    console.log('in')
    this.showSort[column] = true;
    console.log(this.showSort)
  }

  hideSortButton(column: string) {
    this.showSort[column] = false;
  }

  sortColumn(column: string) {
    this.ordenAscendente = !this.ordenAscendente
    if (this.ordenAscendente) {
      this.listPlantillas.sort((a, b) => a[column].localeCompare(b[column]));
    } else {
      this.listPlantillas.sort((a, b) => b[column].localeCompare(a[column]));
    }

  }

  EventSelectPlantilla(plantilla: CorteDiarioModel.PlantillasIndex) {

  }

  openDialog(plantilla: CorteDiarioModel.PlantillasIndex) {
    console.log(plantilla)
    const dialogRef = this.dialog.open(ModalGraphByAnalisisComponent, {
      width: '95%',   // Ancho del diálogo
      height: '95%',  // Alto del diálogo
      disableClose: true,
      data: {
        //GestionesXRuta: this.listGestionXRuta,
        plantillaSelected: plantilla,
        UsuarioId: this.UsuarioId,
        rutasXUsuario: this.listRutasByUsuario
      },
      panelClass: 'dialog-graph'
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
      let reload = dialogRef.componentInstance.Reload;
      if (reload) {
        this.GetPlantillas();
        console.log(reload)

      }
    });
  }

 
  EventOpenModalCrearP() {
    const dialogRef = this.dialog.open(ModalCrearPlantillaComponent, {
      width: '70%',   // Ancho del diálogo
      height: '80%',  // Alto del diálogo
      disableClose: true,
      data: {
        //GestionesXRuta: this.listGestionXRuta,
        UsuarioId: this.UsuarioId,
        isEdit: false,
        RutasXUsuario: this.listRutasByUsuario

      },
       panelClass: 'dialog-crear'
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
      let reload = dialogRef.componentInstance.Reload;
      if (reload) {
        this.GetPlantillas();
        console.log(reload)

      }
    });
  }

  EventOpenModalUpdateP(plantilla: CorteDiarioModel.PlantillasIndex) {
    //let listGestionXRutaNew: CorteDiarioModel.GetGestionesAbiertasXRutas[] = [];
    //this.listGestionXRuta.forEach((gestion,i) => {
    //  if (plantilla.TemplateRutas.some(ruta => ruta.Ruta.Id == gestion.Ruta.Id)) {
    //    //Existe la ruta en la plantilla
    //    listGestionXRutaNew.push(gestion)
    //    listGestionXRutaNew[i].s
    //  }
      
    //})
    const dialogRef = this.dialog.open(ModalCrearPlantillaComponent, {
      width: '70%',   // Ancho del diálogo
      height: '80%',  // Alto del diálogo
      disableClose: true,
      data: {
        //GestionesXRuta: this.listGestionXRuta,
        plantillaSelected: plantilla,
        UsuarioId: this.UsuarioId,
        isEdit: true,
        RutasXUsuario: this.listRutasByUsuario
      },
      panelClass: 'dialog-update'

    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
      let reload = dialogRef.componentInstance.Reload;
      if (reload) {
        this.GetPlantillas();
        console.log(reload)

      }
    });
  }

  EventOpenModalDeleteP(plantilla: CorteDiarioModel.PlantillasIndex) {
   
    const dialogRef = this.dialog.open(ModalDeleteTemplateComponent, {
      width: '30%',   // Ancho del diálogo
      height: '30%',  // Alto del diálogo
      disableClose: true,
      data: {
        plantillaSelected: plantilla,
        UsuarioId: this.UsuarioId,
      }

    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
      let reload = dialogRef.componentInstance.Reload;
      if (reload) {
        this.GetPlantillas();
        console.log(reload)

      }
    });
  }

  GetRutasXUsuario() {
    this.serviceanalisis.GetRutasByUsuarioPId(this.UsuarioId)
      .then(result => {     
        this.listRutasByUsuario = result.Message;
        let rutas = Enumerable.from(this.listRutasByUsuario).select(s => s.Id).toArray();
        //this.GetGestionesAbiertasXRutas(rutas)
      })
      .catch(e => {
        this.dialogService.NotificacionAlert(e);

      });
  }

  GetGestionesAbiertasXRutas(rutas: string[]) {
    //let query = {
    //  "rutaId": [
    //    "96a45aba-bad9-421b-b05b-15cc010afe09",
    //    //"95e7d5fa-f216-44d6-ba02-1a97183b0d65",

    //    //"713ad4ca-2115-453d-93cd-15e0497f62fc",
    //    //"E137616F-9737-4EB2-8A61-22176FC947E8",

    //    //estas no se han usado
    //    //"D612F65E-DD31-4618-AB53-1BF2229E3077",
    //    //"B0CB76FB-4828-4720-B3C8-1BF226F41DBD",
    //    //"7FF1215F-3F4E-4E35-99D3-1BF2249DBD43",
    //    //"6A99402E-C5E1-4202-8F19-1DF49248A1B4",
    //    //"71CEA4A3-737E-4C10-A58A-1BF225E2E9F2",
    //  ],
    //  "sitioAduanaId": null,
    //  "diasAntesxFechaUltimoSello": 20
    //}

    //let rutas = Enumerable.from(plantilla.TemplateRutas).select(s => s.Ruta.Id).toArray();

    let query = {
      "rutas": rutas,
      "sitioAduanaId": null,
      "diasAntesxFechaUltimoSello": null
    }
    this.serviceanalisis.GetGestionesAbiertasXRutas(query)
      .then(result => {
        result.forEach(gestion => {
          gestion.TiempoProceso = parseFloat( gestion.TiempoProceso.toFixed(2) )
        })
        //this.listGestionXRuta = result;

        // this.openDialog(plantilla);

      })
      .catch(e => {
        this.dialogService.NotificacionAlert(e);

      })
  }

  GetPlantillas() {
    this.serviceanalisis.GetPlantillasIndex()
      .then(result => {
        result.forEach(plantilla => {
          plantilla.CreatedDate = plantilla.CreatedDate.includes('0001-01-01T00:00:00') ? '' : moment(plantilla.CreatedDate).format('DD-MM-YYYY');
          plantilla.ModifiedDate = plantilla.ModifiedDate.includes('0001-01-01T00:00:00') ? '' : moment(plantilla.ModifiedDate).format('DD-MM-YYYY');
          plantilla.LastDate = moment(plantilla.CreatedDate).isAfter(plantilla.ModifiedDate) ? plantilla.CreatedDate : plantilla.ModifiedDate;

        });
        result = result.filter(s => s.UsuarioId == this.UsuarioId);;
        this.listPlantillas = result;
        this.listPlantillasFavs = Enumerable.from(result).where(s => s.Favorita == true).toArray();
      })
      .catch(e => {
        this.dialogService.NotificacionAlert(e);

      })
  }

  currentIndex = 0;
  itemsToShow = 7; // Number of cards to show at once

  prev() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    } else {
      this.currentIndex = this.listPlantillasFavs.length - this.itemsToShow;
    }
  }

  next() {
    if (this.currentIndex < this.listPlantillasFavs.length - this.itemsToShow) {
      this.currentIndex++;
    } else {
      this.currentIndex = 0;
    }
  }

}

//@Component({
//  selector: 'modal-change-frontera',
//  templateUrl: 'modal-change-frontera.html',
//})
//export class ModalCambiarFrontera { }
